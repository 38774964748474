//import React from "react"
import { useStaticQuery, graphql } from "gatsby"


export function validateEmail(email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}


export function validatePostcode(postcode) {
  const regex =
    /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/
  //  const regexp = /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/
  if (regex.test(postcode)) {
    return true
  } else {
    return false
  }
}

export function GetImgUrl(localFile___NODE){

    const data = useStaticQuery(graphql`
        {
            allFile {
                edges {
                  node {
                      id
                      publicURL
                      childImageSharp{
                        fluid(quality: 90, maxWidth: 1900) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                  }
                }
            }
        }
    `)

    var imgUrl = {imgName:null, basicUrl: null, fluid: null, gatsbyImageData:null}

    data.allFile.edges.map((item, index) => {

      if(item.node.id === localFile___NODE){
        // if(item.node.publicURL.endsWith(".svg")){
        //   imgUrl.basicUrl = item.node.publicURL
        // } else {
        //   imgUrl.fluid = item.node.childImageSharp.fluid
        //   imgUrl.basicUrl = item.node.publicURL
        // }
        imgUrl.imgName = item.node.name?item.node.name:null
        imgUrl.basicUrl = item.node.publicURL?item.node.publicURL:null
        imgUrl.fluid = item.node.childImageSharp?item.node.childImageSharp.fluid:null
        // imgUrl.gatsbyImageData = item.node.childImageSharp?item.node.childImageSharp.gatsbyImageData:null

        // imgUrl = item.node.publicURL
      }
      return("")
      
    })
    // console.log(imgUrl)
    return(imgUrl)
  }

//Function to determine is slug has '/', if it does, it will remove it

 export function isSlugExisting (slug)  { 
   let newSlug = '';

    if(slug[0] === '/') {
      newSlug=slug.substr(1);
    } else {
      newSlug = slug 
    }

    return newSlug;
 }

export default GetImgUrl;


